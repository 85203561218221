<template>
  <el-card class="box-card" :body-style="{ paddingTop: '0px' }">
    <div slot="header" class="clearfix">
      <img src="@/assets/img/icon/like.png" alt="" class="recommand-icon">
      <span>{{ cardProp.title }}</span>
    </div>
    <div v-if="cardProp.type === 'hotSearch'">
      <div
        v-for="(search, index) in cardProp.dataList"
        :key="index"
        :title="search.keyword"
        class="item"
      >
        <div :class="rank(index + 1)">{{ index + 1 }}</div>
        <router-link target="_blank" :to="`/search?keyword=${search.keyword}&pageNumber=1`">
          {{ search.keyword }}
        </router-link>
      </div>
    </div>
    <div v-if="cardProp.type === 'hotWatch'">
      <div
        v-for="(video, index) in cardProp.dataList"
        :key="index"
        :title="video.title"
        class="item"
      >
        <div :class="rank(index + 1)">{{ index + 1 }}</div>
        <router-link target="_blank" :to="`/video/${video.videoId}`">
          <span style="left: 0;margin-bottom: 0px;color: blue;">{{ video.title | ellipsis }}</span>
        </router-link>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'HotSearch',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 15
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  props: {
    cardProp: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    }
  },
  computed: {
    rank() {
      return (index) => {
        // index是使用时的参数
        // console.log(index)
        if (index === 1) {
          return 'first'
        } else if (index === 2) {
          return 'second'
        } else if (index === 3) {
          return 'third'
        } else if (index === 4) {
          return 'fourth'
        } else if (index === 5) {
          return 'fifth'
        } else if (index === 6) {
          return 'sixth'
        } else if (index === 7) {
          return 'seventh'
        } else {
          return 'other'
        }
      }
    }
  },
  created() {
  },
  methods: {}
}
</script>

<style scoped>
.item {
  height: 25px;
  margin-top: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*行数*/
  -webkit-box-orient: vertical;
}
.item:hover {
  transform: scale(1.1); /*鼠标放上之后元素变成1.1倍大小*/
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.clearfix {
  position: relative;
}
.clearfix span {
  font-size: 20px;
  position: absolute;
  bottom: 8px;
  left: 35px;
}
.box-card {
  width: 100%;
}

.recommand-icon {
  width: 30px;
}

@media screen and (max-width: 768px) {
  .clearfix span {
    font-size: 15px;
    position: absolute;
    bottom: 8px;
    left: 35px;
  }
  .text {
    font-size: 13px;
  }
}

.other {
  display: inline-block;
  background-color: rgb(176, 183, 194);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.first {
  display: inline-block;
  background-color: rgb(255, 0, 0);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.second {
  display: inline-block;
  background-color: rgb(255, 128, 0);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.third {
  display: inline-block;
  background-color: rgb(255, 215, 0);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.fourth {
  display: inline-block;
  background-color: rgb(0, 255, 0);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.fifth {
  display: inline-block;
  background-color: rgb(0, 255, 255);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.sixth {
  display: inline-block;
  background-color: rgb(0, 0, 255);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.seventh {
  display: inline-block;
  background-color: rgb(128, 0, 255);
  width: 22px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
}
.el-card__body {
  padding-top: 0px;
}
</style>
