<template>
  <div id="search-list">
    <!--搜索结果面包屑-->
    <el-breadcrumb
      class="bread"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item>
        对 <span class="result">{{ keyword }}</span> 的搜索结果共有<span class="result"> {{ total }} </span>条记录
      </el-breadcrumb-item>
    </el-breadcrumb>

    <el-row class="movie-list">
      <el-col :md="6">
        <hot-search :card-prop="cardProp" />
      </el-col>
      <el-col :md="18">
        <el-col v-for="(video,index) in dataList" :key="index" :md="6" :sm="8" :xs="12">
          <video-card :video="video" />
        </el-col>
        <!--
					分页按钮：
						page-size：每页显示条数
						total：总条数
						hide-on-single-page： 页数为一时隐藏
				-->
        <el-col :span="24" class="pagination">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-col>
    </el-row>
    <el-row v-if="showEmpty" class="not-result">
      <el-col :span="12" :offset="6">
        <img src="@/assets/img/icon/not-result.png">
        <div>没有结果</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VideoCard from '@/components/card/VideoCard'
import HotSearch from '@/components/card/HotSearch'

import { videoQuery, hotKeyword } from '@/api/search'

export default {
  name: 'Search',
  components: { VideoCard, HotSearch },
  data() {
    return {
      keyword: null,
      currentPage: 1,
      total: 0,
      length: 0,
      pageSize: 12,
      dataList: [],
      showEmpty: false,
      cardProp: {
        title: null,
        type: null,
        dataList: []
      }
    }
  },
  mounted() {
    hotKeyword().then(resp => {
      if (resp.code === 0) {
        this.cardProp.dataList = resp.data
        this.cardProp.title = '热搜排行'
        this.cardProp.type = 'hotSearch'
      }
    })
  },
  created() {
    this.keyword = this.$route.query.keyword
    this.currentPage = parseInt(this.$route.query.pageNumber)
    this.videoQueryWrapper(this.keyword, this.currentPage)

    document.title = '搜索 - ' + this.keyword
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.$router.push({
        path: '/search',
        query: {
          keyword: this.keyword,
          pageNumber: currentPage
        }
      })
      this.$router.go(0)
    },
    videoQueryWrapper(keyword, pageNumber) {
      videoQuery(keyword, pageNumber).then(resp => {
        if (resp.code === 0) {
          this.total = resp.data.totalSize
          this.length = resp.data.totalPages
          this.dataList = resp.data.list

          if (this.total === 0) {
            this.showEmpty = true
          }
        } else {
          this.$notify.error({
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify.error({
          message: error.m,
          type: 'error',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style scoped>
	#search-list {
		padding-left: 6%;
		padding-right: 6%;
		padding-top: 30px;
	}

	.bread {
		font-size: 15px;
	}

	.movie-list {
		padding-top: 15px;
	}

	.pagination {
		text-align: center;
		padding: 10px;
	}

	.result {
		color: red;
	}

	.not-result {
		padding-top: 100px;
		padding-bottom: 100px;
		text-align: center;
	}
</style>
