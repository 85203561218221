import { get, post } from '@/utils/request'

const searchApi = {
  keywordSuggestApi: '/api/search/suggest',
  videoSearchApi: '/api/search/query',
  hotSearchApi: '/api/search/hot'
}

// 关键词建议
export function keywordSuggest(keyword) {
  return get(searchApi.keywordSuggestApi + '?keyword=' + keyword)
}

// 搜索关键词
export function videoQuery(keyword, pageNumber) {
  return get(searchApi.videoSearchApi + '?keyword=' + keyword + '&pageNumber=' + pageNumber)
}

// 热门搜索关键词列表
export function hotKeyword() {
  return get(searchApi.hotSearchApi)
}
